import {
  Account,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

import {cct_alert} from '@/libs/CCT_alert';

import BN from "bn.js";

import * as anchor from '@project-serum/anchor';
import Wallet from '@project-serum/sol-wallet-adapter';

import { establishConnection, getTokenAccountForMintAddress, fetchAccount, fetchAccountOffer, getTokenAccountForTokenAndOwner} from "@/libs/solanaConnection";

import { TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";

import _idl from "@/IDL.json";

const idl = _idl;

// Address of the deployed program.
const program_id = new PublicKey('5iDYGtByoQQwoVo2uPapppwyo8NW7akoCYKasKptHvPv');

// Adress of the token
// const token_address = 'FASohke1j5eRAwQBMqTs3W9d3N54xFqNAg3nZRmGKQdk';
// const token_account = '4mp9iLwjxGqsSpiaP5mjb39JrSe9uJRBSjLp8k6qHtuK';
const token_address = 'Cqt1MDAvMAoLd9KCikCpGiBGvkEv6FLoKz2ndyqUFDDG';
const token_account = 'AbKoQ4AbBckcucwb5vmrhQtkae6kuJxL1JLUEdeX766u';

let connection: Connection;

import axios from 'axios';
import bs58 from "bs58";

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

let backend_url = 'https://cct.effe2.xyz/';

export async function check_for_transaction_confirmation(data, signature, attempts) {
	
	attempts++;
	
	if(attempts == 12) {
		
		data.confirmed = 0;
		
		await axios.post('https://marketplace.bluediamonds.app/cct/buy_tickets', data);

		cct_alert("Transaction failed to confirm, we don't know if it worked, check your wallet and try again.");
		return;
	}
	
	connection = await establishConnection();
	
	await connection.getTransaction(signature).then(async function(result) {

		if(result === null) {

			return new Promise(resolve => {
				setTimeout(async function () {
					resolve(check_for_transaction_confirmation(data, signature, attempts));
				}, 5000);
			});
			// return;
		}
		else {
			// save
			data.confirmed = 1;

			await axios.post('https://marketplace.bluediamonds.app/cct/buy_tickets', data);

			cct_alert("Tickets bought ! Thank you !");
		}
		
		
	});
	
}

export async function check_for_transaction_confirmation_discount(data, signature, attempts) {
	
	attempts++;
	
	if(attempts == 12) {
		
		data.confirmed = 0;
		
		await axios.post(backend_url+'shop/discount/buy', data);

		cct_alert("Transaction failed to confirm, we don't know if it worked, please contact admin staff.");
		return;
	}
	
	connection = await establishConnection();
	
	await connection.getTransaction(signature).then(async function(result) {

		if(result === null) {

			return new Promise(resolve => {
				setTimeout(async function () {
					resolve(check_for_transaction_confirmation_discount(data, signature, attempts));
				}, 5000);
			});
			// return;
		}
		else {
			// save
			data.confirmed = 1;

			let response = await axios.post(backend_url+'shop/discount/buy', data);
      
      let codes = response.data.response.codes;
      
      let here_are_your_codes = codes.join(', ');

			cct_alert("Thank you for buying discount codes. Here are your codes : "+here_are_your_codes, 30000);
		}
		
		
	});
	
}

export async function prepare_buy_ticket_program(wallet_address, wallet_provider, how_many_tickets, raffle_id, ticket_price) {
	
	var tokenAccount = await getTokenAccountForTokenAndOwner(token_address, wallet_address);
			
	await buy_ticket_program(wallet_provider, wallet_address, how_many_tickets, raffle_id, tokenAccount, ticket_price);
	
}

export async function buy_ticket_program(wallet_provider, wallet_address, how_many_tickets, raffle_id, tokenAccount, ticket_price) {
	
	var price_in_tokens = how_many_tickets * ticket_price * 1000000000;
	
	var program = null;
	
	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	program = new anchor.Program(idl, program_id, provider);
	
	
	const initializer = wallet_provider;
	const receiver_token_account = new PublicKey(token_account);
	const aqua = new PublicKey(token_address);
	
	// need to find the token account of the initializer
	const initializer_token_account = new PublicKey(tokenAccount);
    
	const transaction = await program.transaction.initialize(
		new anchor.BN(price_in_tokens),
		{
			accounts: {
				initializer: initializer.publicKey,
				initializerTokenAccount: initializer_token_account,
				receiver: receiver_token_account,
				aqua: aqua,
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			signers: [initializer],
		}
	);

	transaction.feePayer = new PublicKey(wallet_address);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	
	var signature = null;
	
	try {
		
		var signedTransaction = await wallet_provider.signTransaction(transaction);
		
		signature = await connection.sendRawTransaction(signedTransaction.serialize());
	}
	catch(error) {
		
		return;
	}
	
	
	
	var data = {
		
		raffle_id: raffle_id,
		signature: signature,
		wallet: wallet_address,
		tickets: how_many_tickets,
	};
	
	// save
	await check_for_transaction_confirmation(data, signature, 0);
		
	return true;
}

export async function create_discount_program(wallet_provider, wallet_address, aqua, discount, product) {
  
  var program = null;
	
	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	program = new anchor.Program(idl, program_id, provider);
  
  const escrow = anchor.web3.Keypair.generate();
  
  console.log("escrow", escrow);
  console.log("program.account", program.account);
  console.log("program.account.EscrowDiscountCodes", program.account.escrowDiscountCodes);
  
  var createEscrowInstruction = await program.account.escrowDiscountCodes.createInstruction(escrow);
  
  console.log(createEscrowInstruction);
	
	const transaction = await program.transaction.createDiscountCode(
		new anchor.BN(discount),
		new anchor.BN(aqua),
		new anchor.BN(product),
		{
			accounts: {
				initializer: wallet_provider.publicKey,
				escrowAccount: escrow.publicKey,
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
      instructions: [
				createEscrowInstruction,
			],
			// signers: [escrow],
		}
	);

	transaction.feePayer = new PublicKey(wallet_address);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
  
  transaction.sign(escrow)
	
	var signature = null;
	
	try {
		
		var signedTransaction = await wallet_provider.signTransaction(transaction);
		
		signature = await connection.sendRawTransaction(signedTransaction.serialize());
	}
	catch(error) {
    
    console.log(error);
		
		return;
	}
	
	
	/*
	var data = {
		
		raffle_id: raffle_id,
		signature: signature,
		wallet: wallet_address,
		tickets: how_many_tickets,
	};
	
	// save
	await check_for_transaction_confirmation(data, signature, 0);
  */
		
	return [signature, escrow.publicKey];
}


export async function buy_discount_program(wallet_provider, wallet_address, how_many_tickets, escrow_account) {
  
  var program = null;
	
	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	program = new anchor.Program(idl, program_id, provider);
	
	
	const initializer = wallet_provider;
	const aqua = new PublicKey(token_address);
  
  var aqua_token_account_buyer = await getTokenAccountForTokenAndOwner(aqua, wallet_address);
	
	// const cct_token_account = new PublicKey('Bo8AK7XMDsrPa4ikrCKRwYVFJNF8aJ2c6MsFycecjQq9');
	const cct_token_account = new PublicKey('AbKoQ4AbBckcucwb5vmrhQtkae6kuJxL1JLUEdeX766u');
    
	const transaction = await program.transaction.buyDiscountCodes(
		new anchor.BN(how_many_tickets),
		{
			accounts: {
				initializer: initializer.publicKey,
				initializerTokenAccount: aqua_token_account_buyer,
				receiver: cct_token_account,
				escrowAccount: new PublicKey(escrow_account),
				aqua: aqua,
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			signers: [initializer],
		}
	);

	transaction.feePayer = new PublicKey(wallet_address);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	
	var signature = null;
	
	try {
		
		var signedTransaction = await wallet_provider.signTransaction(transaction);
		
		signature = await connection.sendRawTransaction(signedTransaction.serialize());
	}
	catch(error) {
    
    console.log('error');
		
		return;
	}
	
	
	var data = {
		
		escrow_account: escrow_account,
		signature: signature,
		wallet: wallet_address,
		tickets: how_many_tickets,
	};
	
	// save
	await check_for_transaction_confirmation_discount(data, signature, 0);
	
	return signature;
}


export async function fetch_escrow_data(wallet_provider, escrow_account) {
  
  var program = null;
	
	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	program = new anchor.Program(idl, program_id, provider);
	
	
	var data = await program.account.escrowDiscountCodes.fetch(escrow_account);
	return data;
}
