<template>
<div>
  <span style="display: block;
  color: white;
  text-align: center;
  margin-bottom: 50px; font-size: 1.2vw; font-weight: bold;">A psychoactive substance used in ancient rituals</span>
  <div class="raffle-page-background" style="margin-bottom: 100px;" v-for="(discount, key) in discounts" :key="key">
    <div class="raffle-page-card">

      <span class="raffle-page-title">{{ discount.name }}</span>
      <div class="raffle-page-line"></div>

      <div class="raffle-page-info">
        <div class="raffle-page-nft">
          <img :src="discount.image" />
        </div>

        <div class="raffle-page-details">
          <div class="raffle-page-details-text">
            <p>
              <span>Price: </span>
              <span>{{ discount.aqua }} $AQUA /  -{{ discount.discount }}%</span>
            </p>
            <p class="raffle-page-tickets">
              <span class="raffle-page-gradient-font gradient-font" @click="withdraw_one_ticket(discount)">&#x2212;</span>
              <span>{{ discount.how_many_tickets }}</span>
              <span class="raffle-page-gradient-font gradient-font" @click="add_one_ticket(discount)">&#x2b;</span>
            </p>
          </div>

          <div class="raffle-page-button button">
            <div class="button-left-border"></div>
            <div class="button-right-border"></div>
            <div class="button-content" @click="buy_tickets(discount)">
              <span>Buy</span>
              <span>{{ discount.how_many_tickets }}</span>
              <span>Code</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
</template>

<script>

import {buy_discount_program} from "@/libs/rustProgram";
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {raffle_timer_time_left_for_raffle} from '@/libs/raffleTimer';
import {establishConnection} from '@/libs/solanaConnection';
import {cct_alert} from '@/libs/CCT_alert';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

let backend_url = 'https://cct.effe2.xyz/';
// let backend_url = 'http://37.187.116.60/cct/public/';

export default {
  name: 'DiscountCodes',
  data : function (){

    return {

      discounts: [],
      
    }

  },
  methods: {
  
	
	
	withdraw_one_ticket: function(discount) {
		
		discount.how_many_tickets--;
		
		if(discount.how_many_tickets <= 0)
			discount.how_many_tickets = 1;
	},
	
	add_one_ticket: function(discount) {
	
		discount.how_many_tickets++;
	},
	
	buy_tickets: async function(discount) {
	
		if(this.$root.wallet_address === null) {
			
			// alert('Please connect your wallet first');
			this.$root.$emit('display_connection_modal');
			return;
		}
		
		this.$root.loading_gif = true;
    
    console.log(discount);
		
		var signature = await buy_discount_program(getSolanaObject(), getWalletAddress(), discount.how_many_tickets, discount.escrow_account);
		
		console.log(signature);
		
		this.$root.loading_gif = false;
		

	},
	
	confirm_transactions: async function(signatures) {
		
		var result = {
			
			'success': 0,
			'error': 0,
		};
		
		let connection = await establishConnection();
		
		for(var index in signatures) {
		
			let signature = signatures[index];
		
			if(signature === false) {

				result.error++;
			}
			else {

				try {

					var confirmation = await connection.getConfirmedTransaction(signature);
					
					result.success++;
				}
				catch(e) {

					result.error++;
				}
			}
		}
		
		return result;
		
	},
	
	
	

  
	
  },
  mounted: async function(){
	
		var component = this;
	
		axios.get(backend_url+'shop/discount/get').then(function (result) {
		
			component.discounts = result.data.response.discounts;
		});

	},
}
</script>

<style scoped>

</style>